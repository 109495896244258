<template>
  <div>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">Luxembourg eCDF Open Data</span>
        <a
          href="https://ecdf.b2g.etat.lu/ecdf/"
          rel="noopener noreferrer"
          target="_blank"
          class="btn btn-sm btn-light"
        >
          Official Guidelines
          <i class="fe fe-corner-right-up"></i>
        </a>
        &nbsp;
        <a
          href="https://drive.google.com/drive/folders/14WgOlnSffZiEx_JHfP-buRzZ78JzKddR"
          rel="noopener noreferrer"
          target="_blank"
          class="btn btn-sm btn-light"
        >
          Datasets
          <i class="fe fe-corner-right-up"></i>
        </a>
      </h5>
    </div>
    <browse-balances />
  </div>
</template>
<script>
import BrowseBalances from './browse'

export default {
  components: {
    BrowseBalances,
  },
}
</script>
