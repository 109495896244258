<template>
  <div class="mb-5">
    <a-upload
      name="files"
      :action="uploadURL"
      :multiple="true"
      accept="application/pdf"
      @change="handleChange"
    >
      <a-button type="primary"> <a-icon type="upload" /> Upload files (.pdf only) </a-button>
    </a-upload>
  </div>
</template>

<script>
import apiClient from '@/services/api-client'

export default {
  name: 'FileUploader',
  data() {
    return {
      uploadURL: apiClient.defaults.baseURL + '/api/upload',
    }
  },
  methods: {
    handleChange(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        // silent
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully. Start passing it into OCR-system.`)
        this.$emit('fetch')
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
  },
}
</script>
