<template>
  <div class="card">
    <div class="card-header">
      <h5 class="mb-4">
        <strong>Browse</strong>
      </h5>
      <process-loader :count="runningBalanceEventCount" :refresh="fetch"/>
      <file-uploader v-on:fetch="fetch"/>
      <div class="table-operations">
        SHOW
        <a-select default-value="all" style="width: 120px" @change="onStatusChange">
          <a-select-option value="all">
            ALL
          </a-select-option>
          <a-select-option value="done">
            DONE
          </a-select-option>
          <a-select-option value="incorrect">
            INCORRECT
          </a-select-option>
          <a-select-option value="warning">
            WARNING
          </a-select-option>
          <a-select-option value="error">
            ERROR
          </a-select-option>
        </a-select>
        <a-button class="btn btn-success" @click.prevent="exportCheckedRows(validCheckedRowIds)" :disabled="validCheckedRowIds.length === 0">
          Export Selection
        </a-button>
        <a-button class="btn btn-success" @click.prevent="exportAll">
          Export All
        </a-button>
        <a-button class="btn btn-warning" @click.prevent="retryCheckedRows(checkedRowIds)" :disabled="checkedRowIds.length === 0">
          Retry
        </a-button>
        <a-button class="btn btn-danger" @click.prevent="deleteCheckedRows(checkedRowIds)" :disabled="checkedRowIds.length === 0">
          Delete Selection
        </a-button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-metadata" v-if="totalElements > 0">
        <strong>{{ this.totalElements }}/{{ this.length }}</strong> elements found
      </div>
      <a-table :rowKey="record => record.id" :rowSelection="rowSelection" :columns="columns" :dataSource="balanceEventList" size="small" :loading="loading" :pagination="pagination" @change="handleTableChange" :scroll="{ x: 1500 }">
        <span slot="username">
          <a-tag color="gold">guest</a-tag>
        </span>
        <span slot="createdDateTimestamp" slot-scope="text, record">
          <a-tag color="magenta">{{ Number(record.createdDateTimestamp) | moment('DD/MM/YYYY HH:mm:ss') }}</a-tag>
        </span>
        <span slot="duration" slot-scope="text, record">
          <a-tag v-if="record.status === 'RUNNING'" color="cyan">{{ record.status }} <a-spin><a-icon slot="indicator" type="loading" style="font-size: 10px" spin /></a-spin></a-tag>
          <a-tag v-else color="blue">{{ record.duration | duration('hours') }}h{{ record.duration | duration('minutes') }}mn{{ record.duration | duration('seconds') }}s</a-tag>
        </span>
        <span slot="filename" slot-scope="text, record">
          <strong>{{ record.filename }}</strong>
        </span>
        <span slot="filetype" slot-scope="text, record">
          <a-tag v-if="record.status === 'DONE' || record.status === 'INCORRECT'" color="purple"><strong>{{ record.balance.abridged ? 'ABRIDGED' : 'COMPLETE' }}</strong></a-tag>
          <a-tag v-else-if="record.status === 'RUNNING'" color="cyan">{{ record.status }} <a-spin><a-icon slot="indicator" type="loading" style="font-size: 10px" spin /></a-spin></a-tag>
          <a-tag v-else-if="record.status === 'WARNING'" color="orange">{{ record.status }}</a-tag>
          <a-tag v-else color="red">{{ record.status }}</a-tag>
        </span>
        <span slot="company" slot-scope="text, record">
          <a-tag v-if="record.status === 'DONE' || record.status === 'INCORRECT'" color="geekblue"><strong>{{ record.balance.company.name }}</strong></a-tag>
          <a-tag v-else-if="record.status === 'RUNNING'" color="cyan">{{ record.status }} <a-spin><a-icon slot="indicator" type="loading" style="font-size: 10px" spin /></a-spin></a-tag>
          <a-tag v-else-if="record.status === 'WARNING'" color="orange">{{ record.status }}</a-tag>
          <a-tag v-else color="red">{{ record.status }}</a-tag>
        </span>
        <span slot="infos" slot-scope="text, record">
          <span v-if="record.status === 'DONE' || record.status === 'INCORRECT'">
            <a-tag color="geekblue">RCSL: <strong>{{ record.balance.company.rcslNumber }}</strong></a-tag>
            <a-tag color="geekblue">Year: <strong>{{ record.balance.year }}</strong></a-tag>
          </span>
          <a-tag v-else-if="record.status === 'RUNNING'" color="cyan">{{ record.status }} <a-spin><a-icon slot="indicator" type="loading" style="font-size: 10px" spin /></a-spin></a-tag>
          <a-tag v-else-if="record.status === 'WARNING'" color="orange">{{ record.status }}</a-tag>
          <a-tag v-else color="red">{{ record.message }}</a-tag>
        </span>
        <span slot="score" slot-scope="text, record">
          <span v-if="record.status === 'DONE' || record.status === 'INCORRECT'">
            <a-tag v-for="(quality, n) in record.balance.qualityList" :key="n" :color="getBalanceQualityRateColor(quality.rate)">{{ quality.type.replaceAll('_', ' ') }}: <strong>{{ quality.rate }}%</strong></a-tag>
          </span>
          <a-tag v-else-if="record.status === 'RUNNING'" color="cyan">{{ record.status }} <a-spin><a-icon slot="indicator" type="loading" style="font-size: 10px" spin /></a-spin></a-tag>
          <a-tag v-else-if="record.status === 'WARNING'" color="orange">{{ record.status }}</a-tag>
          <a-tag v-else color="red">{{ record.status }}</a-tag>
        </span>
        <span slot="actions" slot-scope="text, record">
          <span v-if="record.status === 'DONE' || record.status === 'INCORRECT'">
            <router-link :to="{ name: 'balance', params: { id: record.balance.id }}"><span style="text-decoration: underline">View</span></router-link>
          </span>
          <a-tag v-else-if="record.status === 'RUNNING'" color="cyan">{{ record.status }} <a-spin><a-icon slot="indicator" type="loading" style="font-size: 10px" spin /></a-spin></a-tag>
          <a-tag v-else-if="record.status === 'WARNING'" color="orange">{{ record.status }}</a-tag>
          <a-tag v-else color="red">{{ record.status }}</a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import apiClient from '@/services/api-client'
import * as balanceService from '@/services/balance'
import FileUploader from './file-uploader'
import ProcessLoader from './process-loader'
import { Modal } from 'ant-design-vue'

const columns = [{
  title: 'User',
  dataIndex: 'username',
  scopedSlots: { customRender: 'username' },
  width: 60,
}, {
  title: 'Creation Date',
  dataIndex: 'createdDateTimestamp',
  scopedSlots: { customRender: 'createdDateTimestamp' },
  width: 150,
}, {
  title: 'Parsing Duration',
  dataIndex: 'duration',
  scopedSlots: { customRender: 'duration' },
  width: 125,
}, {
  title: 'File',
  dataIndex: 'filename',
  scopedSlots: { customRender: 'filename' },
  width: 150,
  ellipsis: true,
}, {
  title: 'File Type',
  dataIndex: 'filetype',
  scopedSlots: { customRender: 'filetype' },
  width: 100,
}, {
  title: 'Company',
  dataIndex: 'balance.company.name',
  scopedSlots: { customRender: 'company' },
  width: 275,
}, {
  title: 'Infos',
  dataIndex: 'balance.year',
  scopedSlots: { customRender: 'infos' },
}, {
  title: 'Score',
  dataIndex: 'balance.quality.assetsScore',
  scopedSlots: { customRender: 'score' },
}, {
  title: 'Actions',
  dataIndex: 'actions',
  scopedSlots: { customRender: 'actions' },
  fixed: 'right',
  width: 100,
}]

export default {
  name: 'BrowseBalances',
  components: { ProcessLoader, FileUploader },
  data() {
    return {
      baseURL: apiClient.defaults.baseURL,
      balanceEventList: [],
      pageSize: 50,
      totalElements: 0,
      length: 0,
      columns,
      runningBalanceEventCount: 0,
      loading: false,
      pagination: {},
      status: 'all',
      validCheckedRowIds: [],
      checkedRowIds: [],
    }
  },
  methods: {
    exportAll() {
      balanceService.exportBalances([])
    },
    exportCheckedRows(ids) {
      balanceService.exportBalances(ids)
    },
    retryCheckedRows(ids) {
      const $this = this
      Modal.confirm({
        title: 'Are you sure?',
        onOk() {
          balanceService.retry(ids, function () {
            $this.fetch()
          })
        },
        onCancel() {
          console.log('Cancel Retry Action')
        },
        class: 'test',
      })
    },
    deleteCheckedRows(ids) {
      const $this = this
      Modal.confirm({
        title: 'Are you sure?',
        onOk() {
          balanceService.deleteBalanceEvents(ids, function () {
            $this.fetch()
          })
        },
        onCancel() {
          console.log('Cancel Deletion')
        },
        class: 'test',
      })
    },
    getBalanceQualityRateColor(rate) {
      if (rate === 100) {
        return 'green'
      } else if (rate >= 95) {
        return 'orange'
      } else {
        return 'red'
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetchBalanceEventList(pagination.current - 1, this.pageSize, this.status)
    },
    connectWebSocket() {
      this.socket = new SockJS(this.baseURL + '/ws')
      this.stompClient = Stomp.over(this.socket)
      this.stompClient.connect(
        {},
        frame => {
          this.stompClient.subscribe('/topic/update', tick => {
            const update = JSON.parse(tick.body).update
            if (update) {
              this.fetch()
            }
          })
        },
        error => {
          console.error(error)
        },
      )
    },
    fetch() {
      const pager = { ...this.pagination }
      this.fetchBalanceEventList(pager.current === undefined || pager.current === 0 ? 0 : pager.current - 1, this.pageSize, this.status)
      this.fetchRunningBalanceEventCount()
    },
    fetchRunningBalanceEventCount() {
      balanceService.fetchRunningBalanceEventCount(this)
    },
    fetchBalanceEventList(page, size, status) {
      this.loading = true
      balanceService.fetchBalanceEventList(this, page, size, status)
      this.validCheckedRowIds = []
      this.checkedRowIds = []
      const $this = this
      setTimeout(function() { $this.loading = false }, 3000)
    },
    onStatusChange(value) {
      this.status = value
      const pager = { ...this.pagination }
      pager.current = 0
      this.pagination = pager
      this.fetchBalanceEventList(0, this.pageSize, this.status)
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          const validCheckedRowIds = []
          selectedRows.forEach(function (row) {
            if (row.balance) {
              validCheckedRowIds.push(row.balance.id)
            }
          })
          this.validCheckedRowIds = validCheckedRowIds
          this.checkedRowIds = selectedRowKeys
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),
      }
    },
  },
  mounted() {
    this.fetch()
    this.connectWebSocket()
  },
}
</script>

<style scoped>
  .table-metadata {
    margin-bottom: -40px;
  }
  .table-operations {
    margin-top: -77px;
    text-align: right;
  }
  .table-operations > button {
    margin-right: 8px;
  }
  .table-operations > .ant-select {
    margin-right: 8px;
    margin-left: 8px;
  }
</style>
