<template>
  <div class="mb-5" v-if="count > 0">
    <a href="#" @click.prevent="refresh">
      <a-spin :tip="message">
        <div class="spin-content">
          <strong>{{ this.count }}</strong> file(s) ingested by the processing system
        </div>
      </a-spin>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ProcessLoader',
  props: {
    count: Number,
    refresh: Function,
  },
  computed: {
    message: function () {
      return this.count + ' pdf(s) are in the pipeline. Duration may vary due to cloud computing availability.  (Click to manually refresh)'
    },
  },
}
</script>

<style scoped>
  .spin-content {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    padding: 30px;
  }
</style>
